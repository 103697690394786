const interactionIntents = {
  LOAN_APPLICATION: 'LOAN_APPLICATION',
  RENEWAL: 'LOAN_APPLICATION:RENEWAL',
  TOP_UP: 'LOAN_APPLICATION:TOP_UP',
  PAYMENT: 'PAYMENT',
  WITHDRAW: 'WITHDRAW',
  SERVICE: 'SERVICE',
};

export default interactionIntents;
